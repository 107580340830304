var myMixin = {
  methods: {
    /**
     * 注册ws分发事件
     * @param {String} key
     * @param {Function} func
     */
    registerEvent(key, func) {
      document.addEventListener(key, func)
    },
    /**
     * 移除ws分发事件
     * @param {String} key
     * @param {Function} func
     */
    removeEvent(key, func) {
      document.removeEventListener(key, func)
    },
    /**
     * 生成事件
     * @param {*} name
     * @param {*} args
     */
    generateEvent(name, args) {
      var evt = document.createEvent('CustomEvent')
      evt.initCustomEvent(name, false, false, args)
      return evt
    }
  }
}

export default myMixin
