import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/vhiweightdemo',
    component: () => import('@/views/pages/vhiweight'),
    hidden: true
  },
  {
    path: '/dashboard',
    component: Layout,
    redirect: '/',
    name: 'Dashboard',
    meta: { title: '数据视图', icon: '', affix: false },
    children: [
      {
        path: '',
        component: () => import('@/views/dashboard/dashboard'),
        name: 'DashboardIndex',
        meta: {
          title: '数据视图',
          icon: 'dashboard',
          code: '1|0|1|1',
          affix: false,
          hideSideBar: true
        }
      }
    ]
  },
  {
    path: '/',
    redirect: '/videoMonitor/device'
  },
  {
    path: '/videoMonitor',
    name: 'VideoMonitor',
    component: Layout,
    meta: {
      title: '视频监控',
      icon: '',
      affix: true,
      hideSideBar: true,
      code: '1|0|1|2'
    },
    redirect: '/videoMonitor/device',
    children: [
      {
        path: 'device',
        name: 'VM_device',
        component: () => import('@/views/device/video-monitor'),
        meta: {
          title: '视频监控',
          icon: '',
          affix: true,
          code: '1|0|1|2',
          hideSideBar: true
        }
      }
    ]
  },
  {
    path: '/vehicleMonitor',
    name: 'VehicleMonitor',
    component: Layout,
    meta: {
      title: '车辆监控',
      icon: '',
      affix: true,
      hideSideBar: true,
      code: '1|0|1|3'
    },
    redirect: '/vehicleMonitor/index',
    children: [
      {
        path: 'index',
        name: 'VehicleMonitorIndex',
        component: () => import('@/views/vehicle/index'),
        meta: {
          title: '车辆监控',
          icon: '',
          affix: true,
          code: '1|0|1|3',
          hideSideBar: true
        }
      }
    ]
  },
  {
    path: '/infoManage',
    component: Layout,
    name: 'InformationManage',
    redirect: '/infoManage/materials/info',
    meta: {
      title: '信息管理',
      icon: '',
      affix: true,
      hideSideBar: false,
      code: '1|0|1|4'
    },
    children: [
      {
        path: 'materials',
        component: () => import('@/views/information/materials/index'),
        name: 'MaterialsManage',
        meta: { title: '物料管理', icon: '', affix: true, code: '2|4|1|6' },
        children: [
          {
            path: 'info',
            component: () => import('@/views/information/materials/info/index'),
            name: 'MaterialsInfoManage',
            meta: {
              title: '物料信息管理',
              icon: '',
              affix: true,
              code: '3|6|1|18'
            }
          },
          {
            path: 'list',
            component: () => import('@/views/information/materials/list/index'),
            name: 'MaterialsListManage',
            meta: {
              title: '工具箱管理',
              icon: '',
              affix: true,
              code: '3|6|1|19'
            }
          }
        ]
      },
      {
        path: 'vehicle',
        component: () => import('@/views/information/vehicle/index'),
        name: 'VehicleManage',
        meta: { title: '车辆管理', icon: '', affix: true, code: '2|4|1|7' },
        children: [
          {
            path: 'info',
            component: () => import('@/views/information/vehicle/info/index'),
            name: 'VehicleInfoManage',
            meta: {
              title: '车辆信息管理',
              icon: '',
              affix: true,
              code: '3|7|1|28'
            }
          },
          {
            path: 'type',
            component: () => import('@/views/information/vehicle/type/index'),
            name: 'VehicleTypeManage',
            meta: {
              title: '车辆类型管理',
              icon: '',
              affix: true,
              code: '3|7|1|29'
            }
          }
        ]
      },
      {
        path: 'company',
        component: () => import('@/views/information/company/index'),
        name: 'CompanyManage',
        meta: { title: '企业管理', icon: '', affix: true, code: '2|4|1|14' }
      },
      {
        path: 'organ',
        component: () => import('@/views/information/organ/index'),
        name: 'OrganManage',
        meta: { title: '机构管理', icon: '', affix: true, code: '2|4|1|8' }
      },
      {
        path: 'device',
        component: () => import('@/views/information/device/index'),
        name: 'DeviceManage',
        meta: { title: '设备管理', icon: '', affix: true, code: '2|4|1|31' },
        children: [
          {
            path: 'pad',
            component: () => import('@/views/information/device/pad/index'),
            name: 'DevicePadManage',
            meta: {
              title: '平板设备管理',
              icon: '',
              affix: true,
              code: '3|31|1|32'
            }
          },
          {
            path: 'info',
            component: () => import('@/views/information/device/video/index'),
            name: 'DeviceVideoManage',
            meta: {
              title: '车载视频管理',
              icon: '',
              affix: true,
              code: '3|31|1|33'
            }
          },
          {
            path: 'gps',
            component: () => import('@/views/information/device/gps/index'),
            name: 'DeviceGpsManage',
            meta: {
              title: '部标一体机',
              icon: '',
              affix: true,
              code: '3|31|1|34'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: Layout,
    meta: {
      title: '统计分析',
      icon: '',
      affix: true,
      hideSideBar: true,
      code: '1|0|1|6'
    },
    redirect: '/statistics/index',
    children: [
      {
        path: 'index',
        name: 'StatisticsIndex',
        component: () => import('@/views/statistics/index'),
        meta: {
          title: '用料统计',
          icon: '',
          affix: true,
          code: '2|26|1|1',
          hideSideBar: false
        }
      }
    ]
  },
  {
    path: '/settingsManagement',
    component: Layout,
    name: 'SettingManage',
    meta: {
      title: '系统设置',
      icon: '',
      affix: true,
      hideSideBar: false,
      code: '1|0|1|5'
    },
    redirect: '/settingsManagement/role',
    children: [
      {
        path: 'role',
        component: () => import('@/views/settings/role/index'),
        name: 'SM_Role',
        meta: { title: '角色管理', icon: '', affix: true, code: '2|5|1|13' }
      },
      {
        path: 'permission',
        component: () => import('@/views/settings/permission/index'),
        name: 'SM_Permission',
        meta: { title: '权限管理', icon: '', affix: true, code: '2|5|1|11' }
      },
      {
        path: 'role/edit',
        component: () => import('@/views/settings/role/edit'),
        name: 'SM_Role_Add',
        meta: { title: '权限新增', icon: '', affix: false, hidden: true }
      },
      {
        path: 'role/edit',
        component: () => import('@/views/settings/role/edit'),
        name: 'SM_Role_Edit',
        meta: { title: '权限编辑', icon: '', affix: false, hidden: true }
      },
      {
        path: 'account',
        component: () => import('@/views/settings/account/index'),
        name: 'SM_Accounts',
        meta: { title: '账号管理', icon: '', affix: true, code: '2|5|1|12' }
      },
      {
        path: 'appUpdate',
        component: () => import('@/views/settings/appupdate/index'),
        name: 'SM_Appupdate',
        meta: { title: 'App管理', icon: '', affix: true, code: '2|5|1|30' }
      }
    ]
  }
]

export const asyncRoutes = []

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: [...constantRoutes]
  })

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

export default router
