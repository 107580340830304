import request from '@/utils/request'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
/**
 * 根据父节点获取子行政区域
 * @param {Object} params
 */
async function getRegionTree(params) {
  const res = await request({
    url: '/communalcs/pcctb',
    method: 'post',
    data: params
  })
  return res.data
}

/**
 *
 * @param {String} url
 * @param {String} title
 * @param {Object} params
 */
async function download(url, title, params) {
  window.utils_.showLoading('正在下载...')
  const res = await request.post(url, params, {
    responseType: 'blob'
  })
  const blob = res.data
  if (blob.size !== 0) {
    const date = window.utils_.parseTime(new Date(), '{y}{m}{d}{h}{m}{s}')
    const fileName = `${title}${date}.xlsx`
    saveAs(blob, fileName)
  }
  window.utils_.hideLoading()
  return res
}
/**
 * 下载图片
 * @param {String} url
 * @param {String} filename
 */
function downloadImage(url, filename) {
  saveAs(url, filename)
}
/**
 * 批量下载图片
 * @param {Array} arr
 * @param {String} filename
 * @param {Array} nameArr
 */
function batchDownloadImage(arr, filename, nameArr) {
  window.utils_.showLoading('正在下载...')
  var blogTitle = filename + '二维码'
  var zip = new JSZip()
  var imgs = zip.folder(blogTitle)
  var baseList = []
  // 下载后图片的文件名，个数应与arr对应

  for (var i = 0; i < arr.length; i++) {
    const image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')

    image.onload = function() {
      const canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height

      const context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)

      const url = canvas.toDataURL() // 得到图片的base64编码数据
      canvas.toDataURL('image/png')

      baseList.push(url.substring(22)) // 去掉base64编码前的 data:image/png;base64,
      if (baseList.length === arr.length && baseList.length > 0) {
        for (let k = 0; k < baseList.length; k++) {
          imgs.file(nameArr[k] + '.png', baseList[k], { base64: true })
        }
        zip.generateAsync({ type: 'blob' }).then(function(content) {
          // see FileSaver.js
          saveAs(content, blogTitle + '.zip')
          window.utils_.hideLoading()
        })
      }
    }
    image.src = arr[i]
  }
}

async function getLogs(moduleType, fieldSerialId, sysType) {
  const res = await request({
    url: '/communalcs/morePlatfOperateAddMod',
    method: 'post',
    data: {
      moduleType,
      fieldSerialId:fieldSerialId || 0,
      sysType: sysType || 'WEB云端'
    }
  })
  return res.data
}

function getRegionIdFromObj(obj) {
  let regionId = 0
  if (!obj.regiLevel) {
    obj.regiLevel = 0
  }
  switch (obj.regiLevel) {
    case 1:
      regionId = obj.regiProvinId
      break
    case 2:
      regionId = obj.regiCityId
      break
    case 3:
      regionId = obj.regiCountrId
      break
    case 4:
      regionId = obj.regiTownId
      break
    case 5:
      regionId = obj.regiBurgId
      break
    case 6:
      regionId = obj.groupId
      break

    default:
      regionId = obj.regiProvinId
      break
  }
  return regionId
}

function getRegionByLevelFromServerObj(obj) {
  const region = {}
  if (!obj.regiLevel) {
    obj.regiLevel = 0
  }
  region.level = obj.regiLevel
  switch (obj.regiLevel) {
    case 1:
      region.id = obj.regiProvinId
      region.code = obj.provinceCode
      region.name = obj.provinceName
      break
    case 2:
      region.id = obj.regiCityId
      region.code = obj.cityCode
      region.name = obj.cityName
      break
    case 3:
      region.id = obj.regiCountrId
      region.code = obj.countrCode
      region.name = obj.districtName
      break
    case 4:
      region.id = obj.regiBurgId
      region.code = obj.burgCode
      region.name = obj.burgName
      break
    case 5:
      region.id = obj.groupId
      region.code = obj.groupCode
      region.name = obj.groupName
      break

    default:
      region.id = ''
      region.code = ''
      region.name = ''
      break
  }
  return region
}

function getRegionForServerFromObj(region) {
  const regionParams = {}
  regionParams.regiLevel = region.level
  switch (region.level) {
    case 1:
      regionParams.regiProvinId = region.id
      break
    case 2:
      regionParams.regiCityId = region.id
      break
    case 3:
      regionParams.regiCountrId = region.id
      break
    case 4:
      regionParams.regiTownId = region.id
      break
    case 5:
      regionParams.regiBurgId = region.id
      break
    case 6:
      regionParams.groupId = region.id
      break

    default:
      regionParams.regiProvinId = region.id
      break
  }
  return regionParams
}

/**
 * 将currentRegion转换成后台的数组结构
 */
function getRegionArrForServerFromObj(regions) {
  const regionResult = {
    organIds: [],
    vehicleIds: []
  }
  regions.forEach(region => {
    switch (region.level) {        
      case 4:
        regionResult.organIds.push(region.id)
        break
      case 5:
        regionResult.vehicleIds.push(region.id)
        break
      default:
        break
    }
  })
  const regionJson = {
    organIds: regionResult.organIds.join(','),
    vehicleIds: regionResult.vehicleIds.join(',')
  }
  return regionJson
}
function getVehiParamsForServerFromObj(regions) {
  const regionResult = {
    regiProvinIds: [],
    regiCityIds: [],
    regiCountrIds: [],
    compIds: []
  }
  regions.forEach(region => {
    switch (region.level) {
      case 1:
        regionResult.regiProvinIds.push(region.id)
        break
      case 2:
        regionResult.regiCityIds.push(region.id)
        break
      case 3:
        regionResult.regiCountrIds.push(region.id)
        break
      case 4:
        regionResult.compIds.push(region.id)
        break
      default:
        break
    }
  })
  const regionJson = {
    regiProvinIds: regionResult.regiProvinIds.join(','),
    regiCityIds: regionResult.regiCityIds.join(','),
    regiCountrIds: regionResult.regiCountrIds.join(','),
    compIds: regionResult.compIds.join(',')
  }
  return regionJson
}

function getOrganVehiParamsForServerFromObj(regions) {
  const regionResult = {
    organIds: [],
    vehicleIds: []
  }
  regions.forEach(region => {
    switch (region.level) {
      case 4:
        regionResult.organIds.push(region.id)
        break
      case 5:
        regionResult.vehicleIds.push(region.id)
        break
      default:
        break
    }
  })
  const regionJson = {
    organIds: regionResult.organIds.join(','),
    vehicleIds: regionResult.vehicleIds.join(',')
  }
  return regionJson
}

async function getMoreCompFromUni(compName) {
  const res = await request({
    url: '/communalcs/moreUser',
    method: 'post',
    data: {
      CompanyName: compName
    }
  })
  return res
}
async function getMoreOrganFromUni(compName) {
  const res = await request({
    url: '/communalcs/moreUser',
    method: 'post',
    data: {
      CompanyName: compName
    }
  })
  return res
}

export {
  getRegionTree,
  download,
  downloadImage,
  batchDownloadImage,
  getLogs,
  getRegionIdFromObj,
  getRegionByLevelFromServerObj,
  getRegionForServerFromObj,
  getRegionArrForServerFromObj,
  getVehiParamsForServerFromObj,
  getOrganVehiParamsForServerFromObj,
  getMoreCompFromUni,
  getMoreOrganFromUni
}
