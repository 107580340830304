var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "container" },
    [
      _c(
        "el-header",
        { staticClass: "header", attrs: { height: "60px" } },
        [
          _c("navbar", {
            on: {
              "region-change": _vm.onRegionChanged,
              "modify-pwd": _vm.onModifyPwdClicked
            }
          })
        ],
        1
      ),
      _c(
        "el-container",
        { staticClass: "body" },
        [
          !_vm.$route.meta.hideSideBar
            ? _c(
                "el-aside",
                { staticClass: "sidebar", attrs: { width: "200px" } },
                [_c("sidebar", { staticClass: "sidebar-container" })],
                1
              )
            : _vm._e(),
          _c(
            "el-main",
            { staticClass: "main-container" },
            [
              _vm.update ? _c("app-main") : _vm._e(),
              _vm.showSettings
                ? _c("right-panel", [_c("settings")], 1)
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  ref: "modifyPwdDiag",
                  attrs: {
                    title: "修改密码",
                    "modal-append-to-body": false,
                    visible: _vm.showPwdDialog
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.showPwdDialog = $event
                    }
                  }
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        rules: _vm.rules,
                        "label-width": "80px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "密码", prop: "pwd" } },
                        [
                          _c("el-input", {
                            attrs: { type: "password" },
                            model: {
                              value: _vm.form.pwd,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "pwd", $$v)
                              },
                              expression: "form.pwd"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认密码", prop: "rePwd" } },
                        [
                          _c("el-input", {
                            attrs: { type: "password" },
                            model: {
                              value: _vm.form.rePwd,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "rePwd", $$v)
                              },
                              expression: "form.rePwd"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.onSubmit }
                            },
                            [_vm._v("确认")]
                          ),
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.showPwdDialog = false
                                }
                              }
                            },
                            [_vm._v("取消")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }