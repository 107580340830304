import { login, logout, modifyPassword } from '@/api/user'
import {
  getToken,
  setToken,
  removeToken,
  setStorage,
  setStorageJson,
  getStorageJson
} from '@/utils/storage'
import { resetRouter } from '@/router'
import CONSTANTS from '@/utils/constants'
import { Promise } from 'core-js'
const userRegion = getStorageJson(CONSTANTS.LOCAL_STORAGE.USER_REGION)
const state = {
  token: getToken(),
  name: '',
  userRegion: userRegion || { regiLevel: '' }
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_USER_REGION: (state, userRegion) => {
    state.userRegion = userRegion
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, userInfo) {
    const cip = window.returnCitySN['cip']
    const { username, password, captcha } = userInfo
    return new Promise((resolve, reject) => {
      login({
        loginName: username.trim(),
        pwd: password,
        validateCode: captcha,
        isValidateCode: 0,
        exterIP: cip,
        lngLat: ''
      })
        .then(res => {
          if (res.success) {
            const data = res.data[0]

            // token
            commit('SET_TOKEN', data.Authorization)
            setToken(data.Authorization)
            // user region
            const regiJson = data.regiJson[0] || {}
            commit('SET_USER_REGION', regiJson)
            setStorageJson(CONSTANTS.LOCAL_STORAGE.USER_REGION, regiJson)
            // username
            setStorage(CONSTANTS.LOCAL_STORAGE.USERNAME, username)
            // set login info
            setStorageJson(CONSTANTS.LOCAL_STORAGE.LOGIN_USER_INFO, data)
            dispatch('app/setLoginUser', data, { root: true })
          }

          resolve(res)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // user logout
  logout({ state, dispatch, commit }) {
     
    commit('SET_TOKEN', null)
    removeToken()
    dispatch('resetStore', null, { root: true })
    return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          // 清除store
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  reset({ commit }) {
    commit('SET_TOKEN', '')
    removeToken()
    resetRouter()

    localStorage.clear()
    sessionStorage.clear()
  },
  modifyPassword({ commit }, data) {
    const params = {
      ...data
    }
    return new Promise((resolve, reject) => {
      modifyPassword(params).then(response => {
        resolve(response)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
