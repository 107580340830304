const TokenKey = 'Token'

export function getToken() {
  return localStorage.getItem(TokenKey)
}

export function setToken(token) {
  return localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  return localStorage.removeItem(TokenKey)
}

export function getStorage(key) {
  return localStorage.getItem(key)
}
export function getStorageJson(key) {
  const data = localStorage.getItem(key)
  return data ? JSON.parse(data) : null
}

export function setStorage(key, item) {
  return localStorage.setItem(key, item)
}

export function setStorageJson(key, item) {
  if (!item) {
    return null
  }
  const data = JSON.stringify(item)
  return localStorage.setItem(key, data)
}

export function removeStorage(key) {
  return localStorage.removeItem(key)
}
