import { constantRoutes, asyncRoutes } from '@/router'
import { getPermissions } from '@/api/permission'

const state = {
  routes: [],
  addRoutes: [],
  permissionTree: {
    id: 0,
    text: '平台',
    edit: false,
    children: [],
    inputValue: '',
    tagInputVisible: false
  }
}

const mutations = {
  SET_ROUTES: state => {
    state.routes = constantRoutes.concat(asyncRoutes)
  },
  SET_PERMISSIONS: (state, permissions) => {
    state.permissionTree.children = permissions
  }
}

const actions = {
  generateRoutes({ dispatch, state, commit }) {
    commit('SET_ROUTES')
    dispatch('navbar/generateNavMenus', state.routes, { root: true })
    return new Promise(resolve => {
      resolve(asyncRoutes)
    })
  },
  async getPermissions({ state, commit }) {
    if (state.permissionTree.children.length == 0) {
      const res = await getPermissions()
      commit('SET_PERMISSIONS', res.data)
    }
    return new Promise(resolve => {
      resolve(state.permissionTree)
    })
  },
  reset({ commit }) {
    commit('SET_PERMISSIONS', [])
    commit('SET_ROUTES')
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
