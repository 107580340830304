module.exports = {
  title: '市政养护车辆',

  /**
   * @type {boolean} true | false
   * @description Whether show the settings right-panel
   */
  showSettings: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production', 'development']
   */
  errorLog: 'production',

  /**
   * @type {string}
   * @description 后台接口服务
   */
  devServer: 'http://172.18.0.16:8080/maintain/',

  /**
   * @type {string}
   * @description qa环境服务
   */
  qaServer: 'http://172.18.0.16:8080/maintain/',

  /**
   * @type {string}
   * @description 生产环境服务
   */
  prodServer: 'http://yhcl.wazert.com/',
  /**
   * @type {string}
   * @description 行政区域json服务
   */
  geomapServer: 'http://yhcl.wazert.com/map',
  /**
   * @type {number}
   * @description 数据视图日期
   */
  dashboardAnalysisDays: 30,
  /**
   * ws开关
   */
  wsSwitch: true
}
