<template>
  <div class="navbar">
    <div class="left-menu">
      <div class="logo">
        <img class="img" :src="logo" />
        <div class="title">{{settings.title}}</div>
      </div>
      <el-link
        v-if="currentRegion.level != userRegion.regiLevel"
        class="back-region"
        :underline="false"
        @click="onBackTopRegion"
      >
        <i class="el-icon-view el-icon-d-arrow-left"></i>返回上级
      </el-link>
      <el-dropdown
        class="location"
        trigger="click"
        size="small"
        placement="bottom-start"
        @command="onLocationChoose"
      >
        <div class="dropdown">
          <span>{{ currentRegion.name }}</span>
          <i class="el-icon-arrow-down el-icon--right" />
        </div>
        <el-dropdown-menu slot="dropdown" class="region-dropmenu">
          <el-dropdown-item
            v-for="item in regionalism"
            :key="item.id"
            :command="item"
          >{{ item.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-menu
        ref="menu"
        style="display:flex;"
        mode="horizontal"
        :default-active="currentMenu.name"
        menu-trigger="click"
        router
        @select="handleSelectMenu"
      >
        <el-menu-item
          v-for="tag in navBarMenus"
          :key="tag.name"
          ref="tag"
          :index="tag.name"
          :class="isActive(tag) ? 'active' : ''"
          :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
          tag="span"
          :route="tag"
        >
          <span v-if="tag.meta.notice && noticeNum > 0">
            <el-badge :value="noticeNum" class="item">{{ tag.meta.title }}</el-badge>
          </span>
          <span v-else>{{ tag.meta.title }}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <div class="right-menu">
      <template>
        <error-log class="errLog-container right-menu-item hover-effect" />
        <screenfull id="screenfull" class="right-menu-item hover-effect" />
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          {{ username }}
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to>
            <el-dropdown-item @click.native="onModifyPwdClicked">修改密码</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ErrorLog from '@/components/ErrorLog'
import Screenfull from '@/components/Screenfull'
import { getStorage } from '@/utils/storage'
import customEvent from '@/mixins/customEvent'
import settings from '@/settings.js'
import Vue from 'vue'
import {
  Menu,
  MenuItem,
  Dropdown,
  DropdownMenu,
  DropdownItem
} from 'element-ui'
Vue.use(Menu).use(MenuItem).use(Dropdown).use(DropdownMenu).use(DropdownItem)

const logo = require('@/assets/logo.png')

export default {
  components: {
    ErrorLog,
    Screenfull
  },
  mixins: [customEvent],
  data() {
    return {
      logo,
      username: '',
      settings
    }
  },
  computed: {
    ...mapGetters([
      'navBarMenus',
      'currentRegion',
      'regionalism',
      'currentMenu',
      'userRegion',
      'noticeNum'
    ]),
    routes() {
      return this.$store.state.permission.routes
    }
  },
  watch: {
    $route(to, from) {
      // 路由变化时修改菜单选中项
      this.$refs.menu.$children.forEach(vm => {
        vm.$el.blur()
      })
      const queryRouteName = to.matched[0].name
      this.$store.dispatch('navbar/selectCurrentMenu', queryRouteName)
    }
  },
  mounted() {
    // 初始化
    this.$store.dispatch('app/generateRegionalism')
    const queryRouteName = this.$route.matched[0].name
    this.handleSelectMenu(queryRouteName)
    this.username = getStorage(this.$const.LOCAL_STORAGE.USERNAME)
  },
  beforeMount() {
    this.registerEvent(
      this.$const.EVENTS.REGION_CHANGED,
      this.onDashboardRegionChanged
    )
  },
  beforeDestroy() {
    this.removeEvent(this.$const.EVENTS.REGION_CHANGED)
  },
  methods: {
    isActive(menu) {
      return menu.name == this.currentMenu.name
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login?redirect=${this.$route.fullPath}`)
    },
    handleSelectMenu: function (name) {
      this.$store.dispatch('navbar/selectCurrentMenu', name)
    },
    onBackTopRegion: function () {
      const self = this
      this.$store.dispatch('app/backTopRegion').then(currentRegion => {
        self.$emit('region-change')
      })
    },
    onLocationChoose: async function (item) {
      this.$store.dispatch('app/setCurrentRegion', item)
      // 将区域选择的事件传给Layout
      this.$emit('region-change')
      await this.$store.dispatch('app/generateRegionalism')
    },
    onDashboardRegionChanged: async function (e) {
      const currentCode = this.currentRegion.code.toString().slice(0, 6)
      if (currentCode == e.regionId) {
        return
      }
      const item = this.regionalism.find(item => {
        if (item.code) {
          const shortCode = item.code.toString().slice(0, 6)
          return shortCode == e.regionId
        }
      })
      if (!item) {
        return
      }
      this.$store.dispatch('app/setCurrentRegion', item)
      // 将区域选择的事件传给Layout
      this.$emit('region-change')
      await this.$store.dispatch('app/generateRegionalism')
    },
    onModifyPwdClicked() {
      this.$emit('modify-pwd')
    },
    onShowNotice(e) {
      this.$store.dispatch('app/toggleNoticeShow')
    }
  }
}
</script>