var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "left-menu" },
      [
        _c("div", { staticClass: "logo" }, [
          _c("img", { staticClass: "img", attrs: { src: _vm.logo } }),
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.settings.title))
          ])
        ]),
        _vm.currentRegion.level != _vm.userRegion.regiLevel
          ? _c(
              "el-link",
              {
                staticClass: "back-region",
                attrs: { underline: false },
                on: { click: _vm.onBackTopRegion }
              },
              [
                _c("i", { staticClass: "el-icon-view el-icon-d-arrow-left" }),
                _vm._v("返回上级 ")
              ]
            )
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            staticClass: "location",
            attrs: {
              trigger: "click",
              size: "small",
              placement: "bottom-start"
            },
            on: { command: _vm.onLocationChoose }
          },
          [
            _c("div", { staticClass: "dropdown" }, [
              _c("span", [_vm._v(_vm._s(_vm.currentRegion.name))]),
              _c("i", { staticClass: "el-icon-arrow-down el-icon--right" })
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "region-dropmenu",
                attrs: { slot: "dropdown" },
                slot: "dropdown"
              },
              _vm._l(_vm.regionalism, function(item) {
                return _c(
                  "el-dropdown-item",
                  { key: item.id, attrs: { command: item } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "el-menu",
          {
            ref: "menu",
            staticStyle: { display: "flex" },
            attrs: {
              mode: "horizontal",
              "default-active": _vm.currentMenu.name,
              "menu-trigger": "click",
              router: ""
            },
            on: { select: _vm.handleSelectMenu }
          },
          _vm._l(_vm.navBarMenus, function(tag) {
            return _c(
              "el-menu-item",
              {
                key: tag.name,
                ref: "tag",
                refInFor: true,
                class: _vm.isActive(tag) ? "active" : "",
                attrs: {
                  index: tag.name,
                  to: {
                    path: tag.path,
                    query: tag.query,
                    fullPath: tag.fullPath
                  },
                  tag: "span",
                  route: tag
                }
              },
              [
                tag.meta.notice && _vm.noticeNum > 0
                  ? _c(
                      "span",
                      [
                        _c(
                          "el-badge",
                          {
                            staticClass: "item",
                            attrs: { value: _vm.noticeNum }
                          },
                          [_vm._v(_vm._s(tag.meta.title))]
                        )
                      ],
                      1
                    )
                  : _c("span", [_vm._v(_vm._s(tag.meta.title))])
              ]
            )
          }),
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        [
          _c("error-log", {
            staticClass: "errLog-container right-menu-item hover-effect"
          }),
          _c("screenfull", {
            staticClass: "right-menu-item hover-effect",
            attrs: { id: "screenfull" }
          })
        ],
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item hover-effect",
            attrs: { trigger: "click" }
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _vm._v(" " + _vm._s(_vm.username) + " "),
              _c("i", { staticClass: "el-icon-caret-bottom" })
            ]),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "" } },
                  [
                    _c(
                      "el-dropdown-item",
                      {
                        nativeOn: {
                          click: function($event) {
                            return _vm.onModifyPwdClicked($event)
                          }
                        }
                      },
                      [_vm._v("修改密码")]
                    )
                  ],
                  1
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function($event) {
                        return _vm.logout($event)
                      }
                    }
                  },
                  [
                    _c("span", { staticStyle: { display: "block" } }, [
                      _vm._v("退出")
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }