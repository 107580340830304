const getters = {
  token: state => state.user.token,
  errorLogs: state => state.errorLog.logs,
  /**
   * 导航栏菜单列表
   */
  navBarMenus: state => state.navbar.menus,
  /**
   * 当前选中菜单
   */
  currentMenu: state => state.navbar.currentMenu,
  /**
   * 当前导航栏选中区域
   */
  currentRegion: state => state.app.currentRegion,
  /**
   * 用户的地域权限
   */
  userRegion: state => state.user.userRegion,
  /**
   * 登录用户信息
   */
  loginUser: state => state.app.loginUser,
  noticeNum: state=> state.app.noticeNum,
  /**
   * 将currentRegion转换成后台结构
   */
  getCurrentRegionForServer: state => {
    const regionParams = {}
    switch (state.app.currentRegion.level) {
      case 1:
        regionParams.regiProvinId = state.app.currentRegion.id
        break
      case 2:
        regionParams.regiCityId = state.app.currentRegion.id
        break
      case 3:
        regionParams.regiCountrId = state.app.currentRegion.id
        break
      case 4:
        regionParams.regiTownId = state.app.currentRegion.id
        break
      case 5:
        regionParams.regiBurgId = state.app.currentRegion.id
        break
      case 6:
        regionParams.groupId = state.app.currentRegion.id
        break

      default:
        regionParams.regiProvinId = state.app.currentRegion.id
        break
    }
    return regionParams
  },
  regionalism: state => state.app.regionalism,
  permissionTree: state =>
    JSON.parse(JSON.stringify(state.permission.permissionTree))
}

export default getters
