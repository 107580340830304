import ReconnectingWebSocket from '@/lib/reconnecting-websocket'
import { getStorageJson } from '@/utils/storage'
import CONSTANTS from '@/utils/constants'
import { getRegionArrForServerFromObj } from '@/api/base'

const loginUser = getStorageJson(CONSTANTS.LOCAL_STORAGE.LOGIN_USER_INFO) || {}
const organJson =
  loginUser.organJson && loginUser.organJson.length > 0
    ? loginUser.organJson[0]
    : {}
const compJson =
  loginUser.compJson && loginUser.compJson.length > 0
    ? loginUser.compJson[0]
    : {}
/**
 * ws模型对象
 * @param {String} operType
 * @param {Object} data
 */
function WSModel(operType, data) {
  return {
    accountId: loginUser ? loginUser.accountId : 0,
    compId: compJson.compId || '',
    organId: organJson.organId || '',
    operType: operType,
    data: data
  }
}
/**
 * Websocket工具类
 * @param {String} url
 * @param {Array} protocols
 * @param {Object} options
 */
function WS(url, protocols, options) {
  this.url = url
  this.protocols = protocols
  this.options = options
  this.heartInterval = 10000
  // 创建事件dom
  this.eventTarget = document.createElement('div')
}

/**
 * 开启监控
 */
WS.prototype.start = function(busis, singlePageData) {
  // 业务领域
  this.busis = busis
  this.singlePageData = singlePageData

  this.rws = new ReconnectingWebSocket(this.url, this.protocols, this.options)
  this.rws.debug = process.env.NODE_ENV === 'development'
  this.rws.timeoutInterval = 5400
  this.rws.open()

  const _self = this

  this.rws.onconnecting = function(e) {
    console.debug('ws', '连接中')
  }
  this.rws.onopen = function(e) {
    _self.readyState = _self.rws.readyState
    console.debug('ws', '连接成功')
    // 注册ws用户
    if (singlePageData) {
      // 单页面用户注册
      _self.registerSinglePage(singlePageData)
    } else {
      _self.register(loginUser ? loginUser.regiJson[0] : {})
    }
    // 开启心跳
    _self.startHeartBeat()
  }

  this.rws.onmessage = function(e) {
    _self.readyState = _self.rws.readyState
    console.debug('ws', '收到消息')
    const res = JSON.parse(e.data)
    if (res.operType == CONSTANTS.WS.OPER_TYPE.LOGIN) {
      if (res.data.msgStatsNum == '1') {
        // 开启业务
        const currentRegion = getStorageJson(
          CONSTANTS.LOCAL_STORAGE.CURRENT_REGION
        )
        _self.busis.forEach(busi => {
          _self.registerEvent(busi.key, busi.targetFunc, busi.data)
          if (currentRegion) {
            _self.setBusiRegion(busi.key, [currentRegion])
          }
        })
      }
    } else if (res.operType == CONSTANTS.WS.OPER_TYPE.BUSINESS_DATA) {
      const data = res.data
      for (const key in CONSTANTS.WS.BUSINESS) {
        const busi = CONSTANTS.WS.BUSINESS[key]
        if (data.instruWeb == busi.key) {
          _self.eventTarget.dispatchEvent(_self.generateEvent(busi.event, data))
        }
      }
    }
  }

  this.rws.onclose = function(e) {
    _self.readyState = _self.rws.readyState
    console.debug('ws', '关闭')
  }

  this.rws.onerror = function(e) {
    _self.readyState = _self.rws.readyState
    console.debug('ws', '连接失败')
  }

  /**
   * 生成事件
   * @param {String} s
   * @param {Array} args
   */
  this.generateEvent = function(s, args) {
    var evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(s, false, false, args)
    return evt
  }
}

/**
 * 开启心跳
 */
WS.prototype.startHeartBeat = function() {
  console.debug('ws', '开启心跳')
  const self = this
  const data = WSModel(CONSTANTS.WS.OPER_TYPE.HEART)
  this.timer = setInterval(() => {
    self.send(data)
  }, this.heartInterval)
}

/**
 * 停止心跳
 */
WS.prototype.stopHeartBeat = function() {
  console.debug('ws', '停止发送心跳')
  if (this.timer) {
    clearInterval(this.timer)
  }
}

/**
 * 发送消息
 * @param {Object} data
 */
WS.prototype.send = function(data) {
  const wsData = JSON.stringify(data)
  console.debug('ws', '发送数据', wsData)
  this.rws.send(wsData)
}

/**
 * 注册ws用户
 * @param {Object} data
 */
WS.prototype.register = function(data) {
  const params = WSModel(CONSTANTS.WS.OPER_TYPE.LOGIN, data)
  this.send(params)
}

/**
 * 单页面应用注册ws
 * @param {Object} data
 */
WS.prototype.registerSinglePage = function(data) {
  const params = { ...WSModel(CONSTANTS.WS.OPER_TYPE.LOGIN), ...data }
  this.send(params)
}

/**
 * 登出ws用户
 */
WS.prototype.logout = function(data) {
  const params = WSModel(CONSTANTS.WS.OPER_TYPE.LOGOUT, data)
  this.send(params)
}

/**
 * 注册ws分发事件
 * @param {WS.BUSINESS} busi
 * @param {Function} func
 * @param {Object} data
 */
WS.prototype.registerEvent = function(busi, func, data) {
  this.eventTarget.addEventListener(busi.event, func)
  if (data) {
    this.registerBusi(busi.key, data)
  }
}

/**
 * 修改业务区域
 * @param {Object} data
 */
WS.prototype.setBusiRegion = function(type, regionList) {
  const data = {
    instruWeb: type.key,
    conditionJson: getRegionArrForServerFromObj(regionList)
  }
  const params = WSModel(CONSTANTS.WS.OPER_TYPE.BUSINESS_DATA, data)
  this.send(params)
}

/**
 * 注册业务
 * @param {Object} data
 */
WS.prototype.registerBusi = function(type, conditionJson) {
  const data = {
    instruWeb: type,
    conditionJson: conditionJson
  }
  const params = WSModel(CONSTANTS.WS.OPER_TYPE.BUSINESS_DATA, data)
  if (this.singlePageData) {
    params.groupId = this.singlePageData.groupId
  }
  this.send(params)
}

/**
 * 移除业务
 * @param {Object} data
 */
WS.prototype.removeBusi = function(type) {
  const data = {
    instruWeb: type.cancelKey
  }
  const params = WSModel(CONSTANTS.WS.OPER_TYPE.BUSINESS_DATA, data)
  this.send(params)
}

/**
 * 移除ws分发事件
 * @param {WS.BUSINESS} busi
 */
WS.prototype.removeEvent = function(busi) {
  this.eventTarget.removeEventListener(busi.event, this.eventTarget)
}

/**
 * 关闭ws
 */
WS.prototype.stop = function() {
  // 登出
  this.stopHeartBeat()
  this.busis.forEach(busi => {
    this.removeEvent(busi.key)
    this.removeBusi(busi.key)
  })
  if (this.singlePageData) {
    this.logout(this.singlePageData)
  } else {
    this.logout(loginUser.regiJson[0])
  }
  this.rws.close()
}

WS.CONNECTING = ReconnectingWebSocket.CONNECTING
WS.OPEN = ReconnectingWebSocket.OPEN
WS.CLOSING = ReconnectingWebSocket.CLOSING
WS.CLOSED = ReconnectingWebSocket.CLOSED

export default WS
