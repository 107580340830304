<template>
  <transition name="fade-transform" mode="out-in">
    <keep-alive :max="10">
      <router-view v-if="currentRegion.name" :key="key"></router-view>
    </keep-alive>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'AppMain',
  data() {
    return {}
  },
  computed: {
    key() {
      return this.$route.path
    },
    ...mapGetters(['currentRegion', 'userRegion'])
  },
  mounted() {
    // this.$store.dispatch('app/generateRegionalism')
  },
  methods: {}
}
</script>
