import path from 'path'

export const state = () => ({
  currentMenu: {},
  menus: []
})

export const mutations = {
  SET_CURRENT_MENU: (state, name) => {
    if (!state.menus || !state.menus[0]) {
      return
    }
    if (!name) {
      name = state.menus[0].name
    }
    state.currentMenu = state.menus.find(menu => menu.name == name)
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  }
}

const createMenu = function(routes, perms, parentPath) {
  const menus = []
  const getMenuFromRoute = function(route) {
    const fullPath = path.resolve(parentPath, route.path)

    let children = []
    if (route.children) {
      const subRoutes = route.children.filter(item => {
        return !item.meta.hidden
      })
      if (subRoutes && subRoutes.length > 0) {
        const subMenus = createMenu(subRoutes, perms, route.path)
        children = subMenus
      }
    }
    const menu = {
      fullPath: fullPath,
      path: route.path,
      name: route.name,
      meta: { ...route.meta },
      children: children
    }
    return menu
  }
  routes.forEach(route => {
    if (route.meta && route.meta.affix) {
      if (route.meta.code && perms.length > 0) {
        const perm = perms.find(perm => perm.showCode == route.meta.code)
        if (perm) {
          const menu = getMenuFromRoute(route)
          menus.push(menu)
        }
      } else {
        const menu = getMenuFromRoute(route)
        menus.push(menu)
      }
    }
  })

  return menus
}

export const actions = {
  selectCurrentMenu({ commit }, name) {
    commit('SET_CURRENT_MENU', name)
  },
  /**
   * 根据路由生成导航栏
   * @param {*} param0
   * @param {*} routes
   */
  async generateNavMenus({ commit, rootGetters }, routes) {
    const perms = rootGetters.loginUser.permJson
    const menus = createMenu(routes, perms, '/')
    // 将route添加到menu中
    commit('SET_MENUS', menus)
  },
  reset({ commit }) {
    commit('SET_CURRENT_MENU', null)
    commit('SET_MENUS', [])
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
