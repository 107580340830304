import request from '@/utils/request'
async function getPermissionList(data) {
  const res = await request({
    url: '/platf0cs/permissionList',
    method: 'post',
    data: data
  })
  return res
}

async function getPermissionById(id) {
  const params = { permId: id }
  const res = await request({
    url: '/platf0cs/permissionOne',
    method: 'post',
    data: params
  })
  return res.data ? res.data[0] : {}
}

async function savePermission(params) {
  const res = await request({
    url: '/platf0cs/permissionAddMod',
    method: 'post',
    data: params
  })
  return res
}

async function removePermission(ids) {
  const res = await request({
    url: '/platf0cs/permissionMoreDele',
    method: 'post',
    data: {
      permIds: ids.join(',')
    }
  })
  return res
}

async function getPermissions(id) {
  const res = await request({
    url: '/communalcs/hierarchyPerm',
    method: 'post',
    data: { permId: id }
  })
  return res
}

export {
  getPermissionList,
  savePermission,
  getPermissionById,
  removePermission,
  getPermissions
}
