import { setStorageJson, getStorageJson, removeStorage } from '@/utils/storage'
import CONSTANTS from '@/utils/constants'
import { getRegionTree, getRegionByLevelFromServerObj } from '@/api/base.js'

const state = {
  device: 'desktop', // 设备类型，用来做响应式的
  currentRegion: getStorageJson(CONSTANTS.LOCAL_STORAGE.CURRENT_REGION) || {
    id: '', // 平台定位区域
    level: '', // 区域级别, 1:省份 2:市级 3:区县 4:街道 5:社区 6:小区
    name: '', // 区域名称
    code: '' // 区域码
  },
  regionTree: getStorageJson(CONSTANTS.LOCAL_STORAGE.USER_REGION) || {
    // 所选区域的区域树
    id: '', // 平台定位区域
    level: '', // 区域级别, 1:省份 2:市级 3:区县 4:街道 5:社区 6:小区
    name: '', // 区域名称
    code: '',
    children: []
  },
  regionalism: [], // navbar区域列表
  loginUser: getStorageJson(CONSTANTS.LOCAL_STORAGE.LOGIN_USER_INFO) || {
    Authorization: '',
    accountId: '',
    dumperJson: [],
    permJson: [],
    platfJson: [],
    regiJson: [],
    roleId: '',
    superJson: []
  },
  noticeNum: 0
}

const mutations = {
  SET_CURRENT_REGION: (state, region) => {
    state.currentRegion = region
    state.regionTree = { ...region, children: [] }
  },
  SET_REGION_LIST: (state, regionList) => {
    state.regionalism = regionList
  },
  SET_REGION_CHILDREN: (state, { parent, children }) => {
    parent.children = children
    state.regionTree = JSON.parse(JSON.stringify(state.regionTree))
  },
  SET_LOGIN_USER: (state, loginUser) => {
    state.loginUser = loginUser
  },
  SET_NOTICE_NUM: (state, noticeNum) => {
    state.noticeNum = noticeNum
  },
}

const actions = {
  setCurrentRegion({ commit }, region) {
    commit('SET_CURRENT_REGION', region)
    setStorageJson(CONSTANTS.LOCAL_STORAGE.CURRENT_REGION, region)
  },
  /**
   * 返回上一级
   */
  backTopRegion({ state, dispatch }) {
    removeStorage(CONSTANTS.LOCAL_STORAGE.CURRENT_REGION)
    return new Promise(resolve => {
      dispatch('generateRegionalism', null).then(() => {
        resolve(state.currentRegion)
      })
    })
  },
  setRegionList({ commit }, regionList) {
    commit('SET_REGION_LIST', regionList)
  },
  setNoticeNum({ commit }, noticeNum) {
    commit('SET_NOTICE_NUM', noticeNum)
  },
  setLoginUser({ commit }, loginUser) {
    commit('SET_LOGIN_USER', loginUser)
  },
  async loadRegionChild({ state, commit }, parent) {
    if (!parent) {
      parent = state.regionTree
    }
    const params = {
      level: parent.level + 1,
      pId: parent.id
    }
    if (!parent.children) {
      const data = await getRegionTree(params)
      const children = data.map(item => {
        return { ...item, children: [] }
      })
      commit('SET_REGION_CHILDREN', { parent, children })
    }
    return parent.children
  },
  async generateRegionalism({ rootGetters, dispatch }) {
    // 如果当前存在区域，则取当前选择区域，否则取用户的区域级别
    const parentRegion =
      getStorageJson(CONSTANTS.LOCAL_STORAGE.CURRENT_REGION) ||
      getRegionByLevelFromServerObj(rootGetters.userRegion)
    const params = {
      level: parentRegion.level || 0,
      id: parentRegion.id || ''
    }
    const data = await dispatch('loadRegionChild', params)

    // 将currentRegion存入state中
    if (parentRegion.id) {
      data.unshift(parentRegion)
      // 设置当前选择区域为上一次记录，否则为列表第一个
      const currentRegion =
        getStorageJson(CONSTANTS.LOCAL_STORAGE.CURRENT_REGION) || data[0]

      dispatch('setCurrentRegion', currentRegion)
    } else {
      dispatch('setCurrentRegion', parentRegion)
    }
    dispatch('setRegionList', data)
    return new Promise(resolve => {
      resolve()
    })
  },
  reset({ commit }) {
    commit('SET_CURRENT_REGION', {
      id: '', // 平台定位区域
      level: '', // 区域级别, 1:省份 2:市级 3:区县 4:街道 5:社区 6:小区
      name: '', // 区域名称
      code: '' // 区域码
    })
    commit('SET_REGION_LIST', [])
    commit('SET_LOGIN_USER', {
      Authorization: '',
      accountId: '',
      dumperJson: [],
      permJson: [],
      platfJson: [],
      regiJson: [],
      roleId: '',
      superJson: []
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
