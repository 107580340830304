<template>
  <el-container class="container">
    <el-header class="header" height="60px">
      <!-- Header content -->
      <navbar @region-change="onRegionChanged" @modify-pwd="onModifyPwdClicked" />
    </el-header>

    <el-container class="body">
      <el-aside v-if="!$route.meta.hideSideBar" class="sidebar" width="200px">
        <!-- Aside content -->
        <sidebar class="sidebar-container" />
      </el-aside>
      <el-main class="main-container">
        <!-- Main content -->
        <app-main v-if="update" />
        <right-panel v-if="showSettings">
          <settings />
        </right-panel>
        <el-dialog
          ref="modifyPwdDiag"
          title="修改密码"
          :modal-append-to-body="false"
          :visible.sync="showPwdDialog"
        >
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="密码" prop="pwd">
              <el-input v-model="form.pwd" type="password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="rePwd">
              <el-input v-model="form.rePwd" type="password"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">确认</el-button>
              <el-button @click="showPwdDialog = false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import resizeHandler from './mixin/ResizeHandler.js'
import { AppMain, Navbar, Settings, Sidebar } from './components'
import { mapState, mapGetters } from 'vuex'
import { getStorageJson } from '@/utils/storage'
import Vue from 'vue'
import { Container, Header, Aside, Main } from 'element-ui'

Vue.use(Container).use(Header).use(Aside).use(Main)
export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar
  },
  mixins: [resizeHandler],
  data() {
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error('密码不能少于6位'))
      } else {
        callback()
      }
    }
    const loginuser = getStorageJson(this.$const.LOCAL_STORAGE.LOGIN_USER_INFO)
    return {
      update: true,
      showPwdDialog: false,
      form: {
        accountId: loginuser.accountId,
        pwd: '',
        rePwd: ''
      },
      rules: {
        pwd: [{ required: true, trigger: 'blur', validator: validatePassword }],
        rePwd: [
          { required: true, trigger: 'blur', validator: validatePassword }
        ]
      }
    }
  },
  // mixins: [ResizeMixin],
  computed: {
    ...mapState({
      showSettings: state => state.settings.showSettings
    }),
    ...mapGetters(['currentMenu'])
  },
  methods: {
    onRegionChanged(e) {
      this.reload()
    },
    /**
     * 刷新AppMain
     */
    reload() {
      this.update = false
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.update = true
      })
    },
    onModifyPwdClicked() {
      this.showPwdDialog = true
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (
            !this.form.pwd ||
            !this.form.rePwd ||
            this.form.pwd == !this.form.rePwd
          ) {
            this.$msg({
              message: '两次密码输入不一致',
              type: 'error',
              duration: 3 * 1000
            })
            return
          }
          this.$store.dispatch('user/modifyPassword', this.form).then(res => {
            if (res.success) {
              this.$msg({
                message: '修改成功',
                type: 'success',
                duration: 3 * 1000
              })
              this.showPwdDialog = false
              this.$store.dispatch('user/logout').then(_ => {
                this.$router.push(`/login?redirect=${this.$route.fullPath}`)
              })
            } else {
              this.$msg({
                message: res.msg,
                type: 'error',
                duration: 3 * 1000
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.scss';

.container {
  height: 100%;
  width: 100%;
  .header {
    padding: 0;
  }

  .body {
    background-color: #ededed;
    height: calc(100% - 60px);
  }
}
</style>
