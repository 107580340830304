export default {
  watch: {
    $route(route) {}
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler)
  },
  mounted() {
    this.setRem()
  },
  methods: {
    $_resizeHandler() {
      if (!document.hidden) {
        this.setRem()
      }
    },
    // 设置 rem 函数
    setRem() {
      if (
        document.documentElement.clientWidth >= 1350 &&
        document.documentElement.clientHeight > 800
      ) {
        document.documentElement.style.fontSize = 16 + 'px'
      } else if (
        document.documentElement.clientWidth >= 1200 &&
        document.documentElement.clientHeight > 700
      ) {
        document.documentElement.style.fontSize = 14 + 'px'
      } else if (
        document.documentElement.clientWidth >= 100 &&
        document.documentElement.clientHeight > 600
      ) {
        document.documentElement.style.fontSize = 12 + 'px'
      } else {
        document.documentElement.style.fontSize = 10 + 'px'
      }
    }
  }
}
