import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueRouter from 'vue-router'
import 'default-passive-events'

import '@/styles/element-theme/index.css'
import './styles/element-variables.scss'

import '@/styles/index.scss' // global css

import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import axios from 'axios'
import * as utils from '@/utils/index'

import WS from '@/utils/wsUtil'
import CONSTANTS from '@/utils/constants'

import {
  Dialog,
  Pagination,
  Input,
  InputNumber,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Option,
  Checkbox,
  CheckboxGroup,
  Table,
  TableColumn,
  DatePicker,
  Row,
  Col,
  Loading,
  MessageBox,
  Message,
  Button,
  Form,
  FormItem,
  Link,
  Image,
  Upload,
  Icon,
  Card,
  Switch,
  Popover,
  Divider,
  Transfer,
  Autocomplete

} from 'element-ui'

Vue.use(Dialog)
  .use(Pagination)
  .use(Input)
  .use(Radio)
  .use(RadioGroup)
  .use(Select)
  .use(Option)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Table)
  .use(TableColumn)
  .use(DatePicker)
  .use(Row)
  .use(Col)
  .use(Button)
  .use(Form)
  .use(FormItem)
  .use(Link)
  .use(Image)
  .use(Upload)
  .use(Icon)
  .use(Card)
  .use(Switch)
  .use(InputNumber)
  .use(Popover)
  .use(Divider)
  .use(RadioButton)
  .use(Transfer)
  .use(Autocomplete)

Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$msg = Message
Vue.prototype.$loading = Loading.service
Vue.use(Loading.directive)
// ws
const url = process.env.VUE_APP_WS_API

const ws = new WS(url, null, { automaticOpen: false })
Vue.prototype.$ws = ws

Vue.prototype.$baseurl = process.env.VUE_APP_BASE_API
Vue.prototype.$staticurl = process.env.VUE_APP_STATIC_API
// utils
window.utils_ = utils
window.constants_ = CONSTANTS
Vue.prototype.$const = CONSTANTS

Vue.config.productionTip = false

// element
// router
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.prototype.$axios = axios
axios.defaults.withCredentials = true

// 自定义指令
Vue.directive('perm', {
  // 当被绑定的元素插入到 DOM 中时……
  componentUpdated: function(el, binding) {
    const code = binding.value
    let hasPerm = false
    const permJson = store.state.app.loginUser.permJson
    if (!permJson || permJson.length == 0) {
      hasPerm = true
    } else {
      const perm = permJson.find(item => item.showCode == code)
      hasPerm = perm != null
    }
    if (!hasPerm) {
      el.parentNode && el.parentNode.removeChild(el)
    }
  }
})

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
