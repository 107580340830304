import request from '@/utils/request'
import Cookies from 'js-cookie'
export async function login(data) {
  const res = await request({
    url: '/accountcs/veriFClou',
    method: 'post',
    data
  })
  return res
}

export async function logout() {
  const res = await request({
    url: '/platf0cs/logout',
    method: 'post'
  })
  return res.data
}

export async function modifyPassword(params) {
  const res = await request({
    url: '/platf0cs/accountMod',
    method: 'post',
    data: params
  })
  return res
}

export async function getCaptcha(params) {
  const res = await request({
    url: '/accountcs/valiCodeFClouBase64?' + new Date().getTime(),
    method: 'get',
    data: params
  })
  const data = res.data[0]
  Cookies.set('RSESSIONID', data.RSESSIONID)
  return data
}
