<template>
  <div>
    <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu
        :default-active="activeMenu"
        :unique-opened="false"
        :collapse-transition="false"
        router
        mode="vertical"
      >
        <sidebar-item
          v-for="menu in currentMenu.children"
          :key="menu.fullPath"
          :item="menu"
          :route="menu"
          :base-path="menu.fullPath"
        />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'
import variables from '@/styles/variables.scss'
import Vue from 'vue'
import { Scrollbar } from 'element-ui'
Vue.use(Scrollbar)
export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters(['currentMenu']),
    activeMenu() {
      const route = this.$route
      return route.path
    },
    variables() {
      return variables
    }
  },
  methods: {}
}
</script>
