const Constants = {
  SESSION_STORAGE: {},
  LOCAL_STORAGE: {
    USERNAME: 'USERNAME',
    USER_REGION: 'USER_REGION',
    CURRENT_REGION: 'CURRENT_REGION',
    APP_PERMISSION: 'APP_PERMISSION',
    LOGIN_USER_INFO: 'LOGIN_USER_INFO'
  },
  WS: {
    OPER_TYPE: {
      LOGIN: 1,
      LOGOUT: 2,
      HEART: 10,
      BUSINESS_DATA: 20
    },
    BUSINESS: {
      VEHICLE_BOARD: {
        key: 10,
        event: 'VEHICLE_BOARD',
        cancelKey: 11
      },
      VEHICLE_MAP: {
        key: 20,
        event: 'VEHICLE_MAP',
        cancelKey: 21
      },
      VEHICLE_LIST: {
        key: 22,
        event: 'VEHICLE_LIST',
        cancelKey: 23
      },
      VEHICLE_TREE: {
        key: 24,
        event: 'VEHICLE_TREE',
        cancelKey: 25
      }
    }
  },
  EVENTS: {
    REGION_CHANGED: 'nav-region-changed'
  },
  permCodes: {
    dashboard: {
      rubishWeight: '0|1|2|100',
      yesterday: '0|1|2|104',
      latestDays: '0|1|2|101',
      stationMap: '0|1|2|103',
      stationRubish: '0|1|2|105',
      userStas: '0|1|2|102',
      pointsStas: '0|1|2|106'
    },
    deviceMoni: {
      list: '0|10|2|107',
      board: '0|10|2|108'
    },
    statistic: {
      weightSearch: '0|17|2|109',
      partRateSearch: '0|18|2|110',
      redBlackSearch: '0|19|2|111'
    },
    userManage: {
      search: '0|24|2|112',
      add: '0|24|2|113',
      edit: '0|24|2|114',
      del: '0|24|2|115',
      imp: '0|24|2|116',
      exp: '0|24|2|117'
    },
    superManage: {
      search: '0|26|2|124',
      add: '0|26|2|125',
      edit: '0|26|2|126',
      del: '0|26|2|127',
      imp: '0|26|2|128',
      exp: '0|26|2|129'
    },
    superSign: {
      search: '0|25|2|118',
      exp: '0|25|2|123'
    },
    dustbinManage: {
      search: '0|27|2|130',
      add: '0|27|2|131',
      edit: '0|27|2|132',
      del: '0|27|2|133',
      imp: '0|27|2|134',
      exp: '0|27|2|135'
    },
    stationManage: {
      search: '0|14|2|136',
      add: '0|14|2|137',
      edit: '0|14|2|138',
      del: '0|14|2|139',
      imp: '0|14|2|140',
      exp: '0|14|2|141'
    },
    vehicleManage: {
      search: '0|15|2|142',
      add: '0|15|2|143',
      edit: '0|15|2|144',
      del: '0|15|2|145',
      imp: '0|15|2|146',
      exp: '0|15|2|147'
    },
    pointsRule: {
      search: '0|28|2|148',
      add: '0|28|2|149',
      edit: '0|28|2|150',
      del: '0|28|2|151'
    },
    pointsRecord: {
      search: '0|29|2|152'
    },
    roleManage: {
      search: '2|163|2|164',
      add: '2|163|2|165',
      edit: '2|163|2|166',
      del: '2|163|2|167',
      log: '2|163|2|174'
    },
    accountManage: {
      search: '0|23|2|158',
      add: '0|23|2|159',
      edit: '0|23|2|160',
      del: '0|23|2|161',
      log: '0|23|2|162'
    },
    permManage: {
      search: '0|22|2|153',
      add: '0|22|2|154',
      edit: '0|22|2|155',
      del: '0|22|2|156',
      log: '0|22|2|157'
    }
  }
}

export default Constants
